body {
  margin: 0;
  font-family:  'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}
/*fbfbfb
f8f9fa
eef4fb
eef4fb
*/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiAppBar-positionStatic{
  background-color: #fff!important;
  color:#424242!important;
}

#nav {
	float: left;
	margin: 0px;
}

#nav ul {
	font: 16px arial, tahoma, verdana;
	list-style: none;
	margin: 0;
    padding: 0;
    
    
}
#nav ul li .sombra{
    border: solid 1px #ccc;
    background-color: #fff;
}

#nav ul li {
	float: left;
	position: relative;
    display: block;
    margin-left: -20px;
    
}

#nav ul li a {
	color: #555;
	background: #FFF;
	text-decoration: none;
	margin: 0 1px;
	padding: 15px 20px;

	display: block;
}

#nav li ul {
	display: none;
}

#nav ul li a:hover {

	color: #066;
}

#nav li:hover ul {
	display: block;
	position: relative;
}

#nav li:hover li {
	float: none;
	font-size: 12px;
}

#nav li:hover a {
	
	color: #333;
}

#nav li:hover li a:hover {
	background: #222;
}
